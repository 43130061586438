import { ButtonAsLink } from '../../components/Button'

import * as styles from './Privacy.styles'
import { MetaFunction } from '@remix-run/cloudflare'

export const privacyPolicyLink =
  'https://yupty.notion.site/Yupty-Privacy-Policy-c82c2336086a4ece844da97a6ca5a600?pvs=4'

export let meta: MetaFunction = () => {
  return [{ title: 'Privacy' }]
}

const Privacy: React.FC = () => {
  // const [cookies, setCookie] = useCookies(['cookie-consent'])
  // const [cookiesAccepted, setCookiesAccepted] = useState(
  //   cookies['cookie-consent'],
  // )

  // const acceptCookies = () => {
  //   setCookie('cookie-consent', true)
  //   setCookiesAccepted(true)
  // }

  // const renderAcceptButton = () => (
  //   <Button
  //     css={styles.button}
  //     onClick={() => {
  //       acceptCookies()
  //     }}
  //     secondary
  //   >
  //     OK, I accept
  //   </Button>
  // )

  // const renderConfirmation = () => (
  //   <div css={styles.cookiesAcceptedConfirmation}>
  //     <i className={'fas fa-heart-circle'} css={styles.heartIcon} /> You have
  //     accepted cookies from Yupty
  //   </div>
  // )

  return (
    <section css={styles.container}>
      <h1 css={styles.title}>Cookies & privacy</h1>
      <h2>Cookies</h2>
      <p>
        <span css={styles.boldText}>
          When logging into Yupty, one cookie will be stored on your computer.{' '}
        </span>
        We use this to recognise you as logged in and identify you as yourself,
        so we can show events and details relevant to you.
      </p>
      {/* {cookiesAccepted ? renderConfirmation() : renderAcceptButton()} */}

      <h2>Privacy</h2>
      <p>
        To find out more about how we use your data, please read our{' '}
        <a rel="noopener noreferrer" target="_blank" href={privacyPolicyLink}>
          Privacy Policy
        </a>
        .
      </p>

      <ButtonAsLink css={styles.button} to="/">
        Back to Home
      </ButtonAsLink>
    </section>
  )
}

export default Privacy
