export const container: Style = ({ breakpoint, size, pageWidth }) => ({
  margin: size.S,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: pageWidth.M,

  [breakpoint.small]: {
    marginTop: size.XL,
    padding: `0 ${size.S}`,
    textAlign: 'left',
  },
})

export const title: Style = () => ({
  marginTop: 0,
  marginBottom: 0,
})

export const button: Style = () => ({
  marginLeft: 0,
  marginRight: 0,
})

export const boldText: Style = () => ({
  fontWeight: 'bold',
})

export const cookiesAcceptedConfirmation: Style = ({
  fontColor,
  size,
  radius,
}) => ({
  fontWeight: 'bold',
  flex: 1,
  textAlign: 'center',
  paddingTop: size.S,
  paddingBottom: size.S,
  color: fontColor.plain.primary,
  borderRadius: radius.secondary,
})

export const heartIcon: Style = ({ color }) => ({
  color: color.success,
})
